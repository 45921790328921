html {
	position: relative;
	min-height: 100%;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 50px;
	background-color: #f5f5f5;
	font-size: 0.9em;
	line-height: 50px;
}

body {
	padding-top: 80px;
	margin-bottom: 60px !important;
	font-size: 16px !important;
	background-color: #e9eaee;
}

.logo-nav {
	height:	80px;
}

.top-space {
	margin-top: 80px;
}

.welcome-msg {
	margin-top: 20px;
}

.form-signin {
	padding-top: 40px;
	width: 400px;
	margin: 0 auto;
}

.form-signin input[type="text"] {
	margin-bottom: -1px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.badge {
	background-color: #FF0000 !important;
}

/*#case-table, .table-bordered #case-table td {
	width: 100%;
}*/

#commalerts-logo, #w51-logo, #resq-logo {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

#w51-logo {
	width: 110px;
	height: 140px;
}

#commalerts-logo {
	width: 120px;
	height: 120px;
}

#resq-logo {
	width: 190px;
	height: 60px;
}

#map-canvas {
	width: 250px;
	height: 200px;
}

.title {
	margin-bottom: 0px;
	display: inline;
}

.highlighted-text {
	color: #FF0000;
}

hr {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.alert-type {
	text-align: center;
	height: 35px;
	line-height: 35px;
	color: #fff;
	font-weight: bold;
}

.btn-margin {
	margin-right: 5px;
}

.page-header {
	font-size: 24px;
}

.required .control-label:after {
	content: " *";
	color: red;
}

.form-delete {
	display: inline;
}

.table-bordered th, .table-style td {
	text-align: center;
}

.paginate {
	display: table;
	margin: 0 auto;
}

#reset-pass {
	text-align: center;
	margin-top: 10px;
}

#form-modal {
	display: inline-block;
}

.hidden {
	display: none;
}

.paginate {
	display: table;
	margin: 0 auto;
}

[ng:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
	display: none !important;
}



.blink {
  animation: blink 1s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}  

.sound-btn {
	margin-right: 600px;
}

#toggle-sound-on, #toggle-sound-off {
	background: transparent;
	border: none !important;
    font-size:0;
}

.resq-background-color {
	background-color: red;
}

.w51-background-color {
	background-color: red;
}

.commalerts-background-color {
	background-color: red;
}

#loading-fade {
    display: none;
    position:absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #ababab;
    z-index: 1001;
    -moz-opacity: 0.8;
    opacity: .70;
    filter: alpha(opacity=80);
}

#loading-modal {
    display: none;
    position: absolute;
    top: 40%;
    left: 45%;
    padding:15px 15px 15px;
    border: 3px solid #ababab;
    box-shadow:1px 1px 10px #ababab;
    border-radius:20px;
    background-color: white;
    z-index: 1002;
    text-align:center;
    overflow: auto;
}

.table-condensed img.img-responsive{
  max-width: 150px !important;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.main-picture {
	max-width: 40%;
	display: block;
	margin: auto;
}

.sultan-alert {
	background-color: #171796;
}

.vip-alert {
	background-color: red;
}

.normal-alert {
	background-color: #ff7f00;
}

.div-right-back {
	background-color: #fff;
}

.info-div {
	padding: 15px;
}

.btn-update {
	width: 5%;
}

.update-info-back {
	background-color: #dddddd;
	padding: 15px;
	border-radius: 5px;
}

.main-row {
	overflow: hidden;
	margin-bottom: 35px;
}

.main-col {
	margin-bottom: -99999px;
    padding-bottom: 99999px;
}

#detailsModalLabel {
	background-color: #888888;
	text-align: center;
	height: 40px;
	margin-left: 10px;
	margin-right: 10px;
	color: #fff;
}

#details-title {
	line-height: 40px;
}

.modal-btn {
	text-align: center;
}

.mode-picture {
	max-width: 10%;
}

.td-mode-pic {
	padding-left: 10px;
}

#report-back {
	background-color: #fff;
	height: 700px;
	border: 1px solid #aaaaaa;
}

#report-title {
	border-left: 4px solid #aaaaaa;
	padding-left: 15px;
}

.report-header {
	margin-top: 20px;
	margin-bottom: 10px;
}

.btn-export, .btn-save {
	margin-right: 20px;
}

.table-style {
	width: 98%;
	margin: 0 auto;
	margin-top: 15px;
	font-size: 15px;
	text-align: center;
}

.table-style>tbody>tr>th {
	color: #0a5faf;
	text-align: center;
}

.table-style>tbody>tr>td {
	text-align: center;
}

#table-blacklist {
	margin-top: 30px;
}

#user-back {
	background-color: #fff;
	height: 550px;
	border: 1px solid #aaaaaa;
	padding-left: 0;
}

.user-content {
	margin-top: 20px;
}

.fa-pencil-square-o {
	color: #52872b;
}

.fa-unlock-alt {
	color: #fe0000;
}

.fa-trash {
	color: #000000;
}

.error-div {
	margin-left: 10px;
}

.view-case-back {
	background-color: #fff;
	padding: 20px;
}


@media (max-width: 1280px) {
	.update-title {
		display: none;
	}

	body {
		font-size: 15px !important;
	}

	.btn {
		font-size: 12px;
	}
}

img.img-responsive.snap-photo {
	width:	50px;
}